<template>
  <div>
    <b-row>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.totalBet)" />
          <span v-text="$t('total_bet')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2
            :class="(report.todayWinLoss < 0) ? 'text-lose-result-color' : ''"
            v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.todayWinLoss)"
          />
          <span v-text="$t('today_win_loss')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2
            v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.todayJackpot)"
          />
          <span v-text="$t('today_jackpot')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2
            v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.todayPromotion)"
          />
          <span v-text="$t('today_promotion')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayNoOfBet).toLocaleString()" />
          <span v-text="$t('today_no_of_bet')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayNoOfPlayer).toLocaleString()" />
          <span v-text="$t('today_no_of_player')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card class="text-center">
          <h2 v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.hitJackpot)" />
          <span v-text="$t('today_hit_amount')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.onlineUsers.length).toLocaleString()" />
          <span v-text="$t('online_users')" />
        </b-card>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        sm="12"
        md="12"
        lg="12"
        class="mt-2"
      >
        <h4>Daily Win/Lose</h4>
        <b-row class="p-1">
          <!-- Filter -->
          <b-card
            footer-tag="footer"
            class="mb-1 card-filter-container"
            style="width: 100%"
          >
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                class="card-filter-item"
              >
                <b-input-group class="mb-1">
                  <b-input-group-prepend is-text>
                    <span
                      style="min-width: 130px;"
                      v-text="$t('date_from')"
                    />
                  </b-input-group-prepend>
                  <b-form-datepicker
                    v-model="filter.dateFrom"
                    :max="filter.dateTo"
                  />
                </b-input-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                class="card-filter-item"
              >
                <b-input-group class="mb-1">
                  <b-input-group-prepend is-text>
                    <span
                      style="min-width: 130px;"
                      v-text="$t('date_to')"
                    />
                  </b-input-group-prepend>
                  <b-form-datepicker
                    v-model="filter.dateTo"
                    :min="filter.dateFrom"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="12"
                lg="12"
                class="card-filter-item mt-1"
              >
                <b-button
                  variant="primary float-right"
                  @click="loadDailyWinLoseData"
                  v-text="$t('search')"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-row>
        <b-overlay
          :show="loading"
          opacity="0.80"
          rounded="sm"
        >
          <div class="card test">
            <div class="card-body">
              <div class="vgt-wrap">
                <div class="vgt-inner-wrap">
                  <div class="vgt-responsive game-history-table">
                    <table class="vgt-table bordered">
                      <thead>
                        <tr>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_date')" />
                          </th>
                          <th class="vgt-left-align">
                            <span v-text="$t('dashboard_total_bet')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_winlose')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_winlose_total')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_jackpot')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_jackpot_total')" />
                          </th>
                          <th class="vgt-left-align">
                            <span v-text="$t('dashboard_jackpot_hits')" />
                          </th>
                          <th class="vgt-left-align">
                            <span v-text="$t('dashboard_jackpot_hits_total')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_winlose_and_jackpot')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_winlose_and_jackpot_total')" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in items"
                          :key="item.id"
                        >
                          <td class="vgt-left-align">
                            <span v-text="item.date_report" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.total_bet).toLocaleString()" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.winlose).toLocaleString()" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.winlose_total).toLocaleString()" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.jackpot).toLocaleString()" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.jackpot_total).toLocaleString()" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.jackpot_hits).toLocaleString()" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.jackpot_hits_total).toLocaleString()" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.winlose_and_jackpot).toLocaleString()" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.winlose_and_jackpot_total).toLocaleString()" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend, BButton,
} from 'bootstrap-vue'

import Account from '@/models/Account'
import 'vue-good-table/dist/vue-good-table.css'
import { EventBus } from '../../eventbus'

export default {
  name: 'Dashboard',
  components: {
    BButton,
    BInputGroup,
    BFormDatepicker,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BInputGroupPrepend,
  },
  data() {
    return {
      socket: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      report: {
        totalBet: 0,
        todayWinLoss: 0,
        todayJackpot: 0,
        todayPromotion: 0,
        todayNoOfBet: 0,
        todayNoOfPlayer: 0,
        hitJackpot: 0,
        onlineUsers: [],
      },
      loading: true,
      items: [],
      perPage: 15,
      totalPage: 0,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        dateFrom: `${(new Date().getFullYear())}-${(new Date().getMonth() + 1)}-01`,
        dateTo: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
      },
      currentFilter: {
        page: 1,
      },
    }
  },
  mounted() {
    this.loadData()
    this.loadDailyWinLoseData()
  },
  methods: {
    async loadData() {
      EventBus.$emit('getMyUpdatedUserData')
      const response = await Account.getDashboard()
      this.report = response.data.report
    },
    async loadDailyWinLoseData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })

      this.$router.replace({ query: searchParams })
      const response = await Account.getDailyWinLose(searchParams)

      this.items = response.data.data
      // this.totalPage = response.data.meta.pagination.total
      // this.perPage = response.data.meta.pagination.per_page

      this.loading = false
    },
  },
}
</script>

<style>
</style>
